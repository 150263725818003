import { useTheme } from '@pancakeswap/hooks'
import { useMatchBreakpoints } from '@pancakeswap/uikit'
import { useTranslation } from '@pancakeswap/localization'
import { useActiveChainId } from 'hooks/useActiveChainId'
import { useMemo } from 'react'
import config, { ConfigMenuItemsType } from '../config/config'

export const useMenuItems = (_onUsCitizenModalPresent?: () => void): ConfigMenuItemsType[] => {
  const {
    t,
    currentLanguage: { code: languageCode },
  } = useTranslation()
  const { chainId } = useActiveChainId()
  const { isDark } = useTheme()
  const { isMobile } = useMatchBreakpoints()

  const menuItems = useMemo(() => {
    const mobileConfig = [...config(t, isDark, languageCode, chainId)]
    mobileConfig.push(mobileConfig.splice(mobileConfig.length > 4 ? 4 : mobileConfig.length - 1, 1)[0])
    return isMobile ? mobileConfig : config(t, isDark, languageCode, chainId)
  }, [t, isDark, languageCode, chainId, isMobile])

  return useMemo(() => {
    return menuItems
  }, [menuItems])
}
