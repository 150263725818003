export const copyText = (text: string, cb?: () => void) => {
  if (navigator.clipboard && navigator.permissions) {
    navigator.clipboard.writeText(text).then(cb);
  } else if (document.queryCommandSupported('copy')) {
    const ele = document.createElement('textarea');
    ele.value = text;
    document.body.appendChild(ele);
    ele.select();
    document.execCommand('copy');
    document.body.removeChild(ele);
    cb?.();
  }
};

export const copyImage = async (
  canvas: HTMLCanvasElement,
  cb?: () => void,
  cbErr?: (err?: string) => void
) => {
  const blob = await new Promise<Blob | null>((resolve) => {
    canvas.toBlob((rst) => {
      resolve(rst);
    });
  });

  if (blob) {
    if (navigator.clipboard && navigator.permissions) {
      navigator.clipboard
        .write([new ClipboardItem({ 'image/png': blob })])
        .then(cb)
        .catch((_e) => {
          // cbErr?.(e?.message);
          const image = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = image;
          link.download = 'screenshot.png';
          link.click();
          cb?.();
        });
    } else {
      const image = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = image;
      link.download = 'screenshot.png';
      link.click();
      cb?.();
    }
  } else {
    cbErr?.('Not found');
  }
};
